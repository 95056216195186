.dashboard-view {
    max-width:400px;
    overflow:hidden;
  }
  
  .dashboard-index-container {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  
  .dashboard-large .dashboard-index-container {
    padding: 15px;
  }
  