.custom-header {
  background-color: #fff;
  padding: 15px;
  padding-left: 30px;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e8e8e8;
  @media screen and (max-width: @screen-sm-max) {
    padding: 10px;
    gap: 5px;
  }
  div {
    display: flex;
    align-items: center;

    @media screen and (max-width: @screen-sm-max) {
      gap: 2px;
      display: inline;
      text-align: center;
      width: 100%;
    }
  }
}
.custom-header > div > i {
  margin-right: 3px;
}
.dark-theme .custom-header {
  background-color: #434f5a;
  border-bottom: 1px solid #525e68;
}
.power-container {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}

.power-mobile-indicator {
  @media screen and (min-width: @screen-sm-max) {
    display: none;
  }
}
.header-value-mobile {
  display: flex !important ;
  flex-direction: column;
}

.header-value-mobile > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-header-content {
  display: flex !important;
  gap: 15px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}
.custom-header-content-mobile {
  display: none !important;
  @media screen and (max-width: @screen-sm-max) {
    display: flex !important;
  }
}
.header-desktop-value {
  gap: 2px;
}
