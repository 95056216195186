.gx-bg-weekly{
    background: linear-gradient(90deg, rgba(48, 119, 189, 1) 0%, rgba(0, 17, 51, 1) 0%, rgba(0, 188, 212, 1) 100%);
    color: wheat;
}

.gx-bg-daily{
    background: linear-gradient(90deg, rgb(255 132 0) 0%, rgb(199 90 0) 0%, rgb(255 204 0) 100%);
    color: black;
}

.gx-bg-hourly{
    background: linear-gradient(90deg, #9E9E9E 0%, #9E9E9E 0%, #000000 100%);
    color: wheat;
}